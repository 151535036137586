<template>
  <div :style="getElementStyle" v-if="data.properties.filed_content != 'Hide'">
    <div class="form-group" v-if="!refresh">
      <el-row align="middle">
        <el-col :span="showLabel && !isDefalutPos ? 8 : 24">
          <label
            for="text"
            v-if="data.properties.filed_content != 'Hide' && ! hasLabel"
            :style="getLabelStyles"
            
            :class="{ 'highlight-border': highlight }"
            @change="applyFormRules"
          >
         
          <span v-if="!data.is_hide_question_no">{{ data.questionIndex }}.{{ label||data.label }}</span> <span v-if="data.is_hide_question_no">{{ data.question_before }}<span v-if="data.question_before && data.question_before !== ''">.</span>{{ label||data.label }}</span>
          </label>
          <span
            v-if="data.validations.required && ! hasLabel && !isView"
            style="color: red; font-weight: bold"
          >
            *
          </span>
          <span>
            <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
            >
            <i
              class="el-icon-info"
              slot="reference"
            ></i>
            </el-popover>
          </span>
        </el-col>

        <el-col :span="showLabel 
          &&
          !isDefalutPos
          ?
          12
          :
          24"  @change="applyFormRules">
          <el-input
            v-if="!isView && data.question_type == 'TEXT'"
            :style="getStyle"
            type="textarea"
            placeholder="Your Answer"
            v-model="form[data.key]"
            @change="applyFormRules"
            :disabled="checkReadonly()"
          >
          </el-input>
         
          <el-radio-group
            v-model="form[data.key]"
            v-if="
              !isView &&
              data.question_type == 'YES_OR_NO' &&
              data.properties.filed_content != 'Hide'
            "
            @change="applyFormRules"
            :disabled="checkReadonly()"
          >
            <el-radio
              label="YES"
              border
            >YES</el-radio>
            <el-radio
              label="NO"
              border
            >NO</el-radio>
          </el-radio-group>
          <el-checkbox-group
            v-model="form[data.key]"
            v-if="
              !isView &&
              data.question_type == 'MCQ' &&
              data.question_options_format &&
              data.question_options_format != 'DROP_DOWN'
            "
            :max="
              data.max_selection
                ? data.max_selection
                : data.options && data.options.length
                ? data.options.length
                : 1
            "
            @change="checkAnswered"
          >
            <ol :class="data.question_options_format.toLowerCase()">
              <li
                v-for="(option, index) of data.options"
                :key="index + option + '_option'"
              >
                <el-checkbox
                  :value="option"
                  :label="option"
                  border
                >
                  {{ String.fromCharCode((index % 52) + (index > 25 ? 71 : 65)) }}.
                  {{ option }}</el-checkbox>
              </li>
            </ol>
          </el-checkbox-group>

          <el-select
            v-model="form[data.key]"
            :disabled="checkReadonly()"
            v-if="
              !isView &&
              data.question_type == 'MCQ' &&
              data.question_options_format &&
              data.question_options_format == 'DROP_DOWN' &&
              data.properties.filed_content != 'Hide'
            "
            :max="
              data.max_selection
                ? data.max_selection
                : data.options && data.options.length
                ? data.options.length
                : 1
            "
            multiple
            :multiple-limit="
              data.max_selection
                ? data.max_selection
                : data.options && data.options.length
                ? data.options.length
                : 1
            "
            @change="applyFormRules"
            collapse-tags
          >
            <el-option
              v-for="(option, index) of data.options"
              :key="index"
              :value="option"
              :label="option"
            ></el-option>
          </el-select>
        </el-col>

        <el-col
          :span="24"
          v-if="isView && data.properties.filed_content != 'Hide'"
        >
          <el-input
            v-if="data.question_type == 'TEXT'"
            :style="getStyle"
            type="textarea"
            :placeholder="data.label"
            v-model="form[data.key]"
            @change="applyFormRules"
            readonly
          >
          </el-input>

          <el-radio-group
            v-model="form[data.key]"
            v-if="data.question_type == 'YES_OR_NO'"
            :fill="'#898ff5'"
            :disabled="true"
            @change="applyFormRules"
          >
            <el-radio
              label="YES"
              border
              @change="applyFormRules"
            >YES</el-radio>
            <el-radio
              label="NO"
              border
              @change="applyFormRules"
            >NO</el-radio>
          </el-radio-group>

          <el-checkbox-group
            v-model="form[data.key]"
            v-if="
              data.question_type == 'MCQ' &&
              data.question_options_format != 'DROP_DOWN'
            "
            :max="
              data.max_selection
                ? data.max_selection
                : data.options && data.options.length
                ? data.options.length
                : 1
            "
            :disabled="true"
            @change="applyFormRules"
          >
            <ol :class="data.question_options_format.toLowerCase()">
              <li
                v-for="(option, index) of data.options"
                :key="index"
              >
                <el-checkbox
                  :label="option"
                  border
                >
                  {{ String.fromCharCode((index % 52) + (index > 25 ? 71 : 65)) }}.
                  {{ option }}</el-checkbox>
              </li>
              <li v-if="data.has_other_option">
                <el-checkbox
                  value="others"
                  label="Others"
                  border
                >
                  {{ String.fromCharCode(data.options.length + 65) }}.
                  Others</el-checkbox>
              </li>
            </ol>
          </el-checkbox-group>
          <div
            v-if="data.has_other_option && showMSQ && otherOptionSelected"
            class="mt-1"
          >
            {{
              data.other_option_label
                ? data.other_option_label
                : "Enter other option"
            }}
            <el-input
              v-model="form[data.key + '/otheroption']"
              disabled
            >
            </el-input>
          </div>
          <el-select
            disabled
            v-model="form[data.key]"
            v-if="
              data.question_type == 'MCQ' &&
              data.question_options_format &&
              data.question_options_format == 'DROP_DOWN'
            "
            :max="
              data.max_selection
                ? data.max_selection
                : data.options && data.options.length
                ? data.options.length
                : 1
            "
            multiple
            :multiple-limit="
              data.max_selection
                ? data.max_selection
                : data.options && data.options.length
                ? data.options.length
                : 1
            "
            collapse-tags
            @change="applyFormRules"
          >
            <el-option
              v-for="(option, index) of data.options"
              :key="index"
              :value="option"
              :label="option"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";

export default {
  mixins: [userPermissionsHelper],
  components: {},
  props: ["data","label", "form", "isView", "hasLabel","highlight","checkIsDisabled"],
  computed: {
    getMaxValue() {
      return this.data.max_selection || 1;
    },
    getStyle() {
      return `height:${this.data.height - 30}px`;
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}`
        : "";
    },
    showMSQ() {
      return (
        this.data.question_type == "MCQ" &&
        this.data.question_options_format != "DROP_DOWN"
      );
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size = this.data.styles.font.font_size
          ? this.data.styles.font.font_size
          : "";
        let bold =
          this.data.styles.font.style == 1 || this.data.styles.font.style == 3
            ? "bold"
            : "";
        let italic =
          this.data.styles.font.style == 2 || this.data.styles.font.style == 3
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += "font-size:" + font_size + "px;";

        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
      }
      return borderStyle;
    }
  },
  data() {
    return {
      isDefalutPos: true,
      showLabel: true,
      otherOptionSelected: false,
      result: [],
      refresh: true,
    };
  },
  mounted() {
    // if (!this.form[this.data.key] && this.data.question_type == "MCQ") {
    //   this.$set(this.form, this.data.key, []);
    // }
    let data = this.form[this.data.key];
    let otherOption = (data && typeof data == "object" && data.length
      ? data
      : []
    ).find(e => e.toLowerCase() == "others");
    if (this.data.has_other_option && otherOption) {
      this.$emit("applyFormRules");
      this.otherOptionSelected = true;
    }
    //set format
    if (!this.form[this.data.key]) {
      if (
        !this.isView &&
        this.data.question_type == "MCQ" &&
        this.data.question_options_format &&
        this.data.question_options_format != "DROP_DOWN"
      ) {
        this.$emit("applyFormRules");
        this.$set(this.form, this.data.key, []);
      }
    }
    //after type set then only have to display component
    setTimeout(() => {
      this.refresh = false;
    }, 500);
  },
  methods: {
    checkAnswered(data) {
      this.applyFormRules();
      this.$set(this.form, this.data.key, data);
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
        if (this.data.styles.labelStyle == "right") {
          this.isDefalutPos = false;
        }
      }
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      return !this.fieldFilledByCurrentUser(this.data.filled_by);
    }
  }
};
</script>
<style lang="scss">
.highlight-border {
color: red
}
.horizantal_list {
  display: inline-flex;
  gap: 10px;
  padding-left: 0;
  margin-top: 10px;
}
.vertical_list {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding-left: 0;
  margin-top: 10px;
}
</style>
